import React, { useCallback, useEffect, useState } from 'react';

import { Image } from '../Common/Image';
import { RichText } from '../../richTextOptions';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ShareSocials from '../Common/ShareSocials';
import { FacebookShareButton } from 'react-share';
import { graphql, useStaticQuery } from 'gatsby';

const getData = graphql`
     query YearNomineesWacky {
          allContentfulWackyPetCategory(sort: { order: ASC, fields: slug }) {
               nodes {
                    slug
               }
          }
     }
`;

const WackyStory = ({ story }) => {
     const today = new Date();

     const dataQuery = useStaticQuery(getData);
     const slugs = dataQuery.allContentfulWackyPetCategory.nodes;
     let getNewYear = today.getFullYear();

     if (slugs && slugs.length > 0) {
          getNewYear = Math.max(...slugs.map((slug) => parseInt(slug.slug.slice(0, 4), 10)));
     }

     const { category } = story;
     let listStoriesInCategory = category.wacky_pet___story;
     let categorySlug = '/wackypetnames/' + category.slug + '/';

     let isVotingOn = (story.turnOnVotingForThisStory || (category.turnOnVotingForThisCategory && story.turnOnVotingForThisStory !== false)) && category.slug.includes(getNewYear); // story option overrides category option

     let petType = category.slug.toLowerCase().includes('dog') ? 'dog' : category.slug.toLowerCase().includes('cat') ? 'cat' : 'exotic';
     const COOKIE_NAME = petType === 'dog' ? 'dog_voting_visitor' : petType === 'cat' ? 'cat_voting_visitor' : 'exotic_voting_visitor';
     const DAYS_TO_EXPIRE = 1;
     const [isVoted, setIsVoted] = useState(!!Cookies.get(COOKIE_NAME));
     const petName = story.petName || story.title;
     const imageStoryUrl = story.socialImage?.file.url;
     const storyImage = story.image.file.url;
     const styleCardImage = {
          backgroundImage: `url(${storyImage})`
     };

     const replacePetName = useCallback((string) => {
          // return string.replace(/##wackypetname##/gi, petName.toUpperCase());
          return string.replace(/##wackypetname##/gi, petName);
     }, []);

     const [currentHref, setCurrentHref] = useState('');
     useEffect(() => {
          if (window) {
               setCurrentHref(window.location.href);
          }
     }, []);

     const guid = useCallback(() => {
          const s4 = () => {
               return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
          };
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
     }, []);

     if (isVotingOn) {
          let votingButtonSet = true;
          let enabledText = '';
          let disabledText = '';
          let salesforceUrl = '';

          if (story.votingButton) {
               enabledText = replacePetName(story.votingButton.enabledText);
               disabledText = replacePetName(story.votingButton.disabledText);
               salesforceUrl = story.votingButton.salesforceUrl;
          } else if (category.votingButton) {
               enabledText = replacePetName(category.votingButton.enabledText);
               disabledText = replacePetName(category.votingButton.disabledText);
               salesforceUrl = category.votingButton.salesforceUrl;
          } else {
               console.log('Please set voting button for the category which this story belongs to or for this story');
               votingButtonSet = false;
          }

          const handleClose = () => {
               setShow(false);
          };

          const [show, setShow] = useState(false);
          const handleShow = () => setShow(true);

          const dataVotingButton = JSON.parse(JSON.stringify(story.votingButton).replace(/##petName##/g, story.petName));

          const PopupVoting = () => {
               return (
                    <>
                         <Modal className="Popupsuccess popup-wacky-voting" show={show} onHide={handleClose}>
                              <div className="group-content-popup">
                                   <div className="content-popup">
                                        <Modal.Body className="text">
                                             {/* <strong>Thanks for voting for</strong> */}
                                             {/* <h2>{story.petName}</h2>
                                             <p>You can come back and vote again for {story.petName} once a day.</p>
                                             <p>Share who you voted for and enter to win [offer]!</p> */}
                                             <RichText data={dataVotingButton.textThankYou} />
                                        </Modal.Body>
                                        <Modal.Footer>
                                             <Button variant="primary">
                                                  <FacebookShareButton url={currentHref} appId="623395759254133">
                                                       Share on Facebook
                                                  </FacebookShareButton>
                                             </Button>
                                        </Modal.Footer>
                                   </div>
                                   <div className="spacing-popup"></div>
                              </div>
                              <div className="image-popup">
                                   <Image image={story.image} />
                              </div>
                         </Modal>
                    </>
               );
          };

          var VotingButton = () => {
               if (!votingButtonSet) {
                    return <></>;
               }

               const voting = (e) => {
                    var votingPick = e.target.name;
                    var ipAddr = '';

                    axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((response) => {
                         if (response.status === 200) {
                              const myRegexp = /^(?:ip)=(.*)$/gm;
                              let responseString = response.data;
                              let match;

                              match = myRegexp.exec(responseString);
                              while (match != null) {
                                   ipAddr = ipAddr.concat(match[1] + '\n');
                                   match = myRegexp.exec(responseString);
                              }
                         } else {
                              ipAddr = guid();
                         }

                         let cookieCreateDate = dayjs();
                         let cookieExpireDate = cookieCreateDate.add(DAYS_TO_EXPIRE, 'day');
                         let cookieExpireTemp = cookieExpireDate.toISOString().slice(0, 10);

                         let cookieValue = {
                              ipAddr,
                              cookieExpireDate: cookieExpireDate.toString(),
                              votingChoice: petName
                         };

                         //set cookie
                         Cookies.set(COOKIE_NAME, JSON.stringify(cookieValue), { expires: DAYS_TO_EXPIRE });
                         setIsVoted(true);
                         handleShow();

                         if (salesforceUrl) {
                              const questionMarkIndex = salesforceUrl.indexOf('?');
                              let paramString = questionMarkIndex === -1 ? '' : salesforceUrl.substr(questionMarkIndex + 1);
                              var url = `${salesforceUrl}${paramString ? '&' : '?'}ipAddress=${ipAddr}&cookieExpireDate=${encodeURIComponent(
                                   cookieExpireTemp
                              )}&votingPick=${encodeURIComponent(votingPick)}&votedSpecies=${petType}`;

                              axios.post(url).then((response) => {
                                   // console.log(response);
                              });
                              // window.location.href = url;
                         }
                    });
               };
               return (
                    <>
                         {isVoted ? (
                              <>
                                   <PopupVoting />
                                   <button className="btn-voting disabled" disabled>
                                        {petType === 'cat' && <img loading="lazy" src="/images/icons/iconcat.svg" alt="IconCat" />}
                                        {petType === 'dog' && <img loading="lazy" src="/images/icons/Icon-Dog2022.svg" alt="IconDog" />}
                                        {petType === 'exotic' && <img loading="lazy" src="/images/icons/iconexotic.svg" alt="IconExotic" />}
                                        {disabledText}
                                   </button>
                              </>
                         ) : (
                              <>
                                   <PopupVoting />
                                   <button className="btn-voting" name={petName} onClick={voting}>
                                        {petType === 'cat' && <img loading="lazy" src="/images/icons/iconcat.svg" alt="IconCat" />}
                                        {petType === 'dog' && <img loading="lazy" src="/images/icons/Icon-Dog2022.svg" alt="IconDog" />}
                                        {petType === 'exotic' && <img loading="lazy" src="/images/icons/iconexotic.svg" alt="IconExotic" />}
                                        {enabledText}
                                   </button>
                              </>
                         )}
                    </>
               );
          };
     }

     return (
          <>
               <section className="wacky-story-information">
                    <div className="container">
                         <div className="row wacky-story-detail">
                              <div className="col-lg-8 col-md-7 wacky-story-name">
                                   <div className="wacky-story-header">
                                        <div className="wacky-story-title">
                                             <h1>{story.title}</h1>
                                             <p>{story.shortDescription}</p>
                                             {story.wackySubTitle?.wackySubTitle && <h3>{story.wackySubTitle.wackySubTitle}</h3>}
                                        </div>
                                        <div className="wacky-story-image" style={styleCardImage}>
                                             <Image image={story.image} />
                                        </div>
                                   </div>
                                   <div className="wacky-story-content">
                                        {story.body && <RichText data={story.body} />}
                                        <div className="article-page-socials wacky-story-btn">
                                             {isVotingOn && <VotingButton />}
                                             <ShareSocials imageArticleUrl={imageStoryUrl} />
                                        </div>
                                   </div>
                              </div>

                              <div className="col-lg-4 col-md-5 wacky-story-menu">
                                  <div className="list-top-pet">
                                        <ul>
                                             {listStoriesInCategory &&
                                                  listStoriesInCategory.map((val, i) => {
                                                       if (val.id == story.id) {
                                                            return (
                                                                 <li key={i}>
                                                                      <a href={categorySlug + val.slug + '/'} className="active">
                                                                           {val.title}
                                                                      </a>
                                                                 </li>
                                                            );
                                                       }
                                                       return (
                                                            <li key={i}>
                                                                 <a href={categorySlug + val.slug + '/'}>{val.title}</a>
                                                            </li>
                                                       );
                                                  })}
                                        </ul>
                                   </div>
                              </div>
                              <div className="col-lg-4 col-md-5 wacky-story-dropdown">
                                     <Dropdown>
                                        <Dropdown.Toggle>{story.petName}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                             {listStoriesInCategory.map((option, i) => (
                                                  <Dropdown.Item key={i} href={categorySlug + option.slug + '/'} className={option.slug === story.slug ? 'active' : ''}>
                                                       {option.title}
                                                  </Dropdown.Item>
                                             ))}
                                        </Dropdown.Menu>
                                   </Dropdown>

                              </div>
                          </div>
                    </div>
               </section>
          </>
     );
};

export default WackyStory;